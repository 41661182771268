<template>
  <div class="content_wrapper">

    <default-form :block_data="block_data" :custom_fields="custom_fields" :is_mobile_resolution="ui.is_mobile_resolution"></default-form>
    
    <!-- <div class="contact_wrapper">
      <base-title v-if="block_data.contact_title" :text="block_data.contact_title" :text-align-center="ui.is_mobile_resolution"></base-title>

      <div class="contact_container" v-if="block_data.contact_title">
        <div class="contact_item">
          <div class="left">
            <p v-if="custom_fields.address_label">{{ custom_fields.address_label }}:</p>
          </div>
          <div class="right">
            <p v-if="custom_fields.address_street">{{ custom_fields.address_street }}</p>
            <p v-if="custom_fields.address_post_code">{{ custom_fields.address_post_code }}</p>
            <p v-if="custom_fields.address_county">{{ custom_fields.address_county }}</p>
            <p v-if="custom_fields.address_country">{{ custom_fields.address_country }}</p>
          </div>
        </div>
        <div class="contact_item">
          <div class="left">
            <p v-if="custom_fields.email_label" >{{ custom_fields.email_label }}:</p>
          </div>
          <div class="right">
            <p v-if="custom_fields.contact_email">{{ custom_fields.contact_email }}</p>
          </div>
        </div>
        <div class="contact_item">
          <div class="left">
            <p v-if="custom_fields.phone_label">{{ custom_fields.phone_label }}:</p>
          </div>
          <div class="right">
            <p v-for="(phone_number, index) in phone_numbers" :key="index">{{ phone_number }}</p>
          </div>
        </div>
        <div class="contact_item">
          <div class="left">
            <p v-if="custom_fields.fax_label">{{ custom_fields.fax_label }}:</p>
          </div>
          <div class="right">
            <p v-for="(fax_number, index) in fax_numbers" :key="index">{{ fax_number }}</p>
          </div>
        </div>
      </div>
    </div> -->

  </div>
</template>

<script>

import DefaultForm from './forms/DefaultForm.vue';

export default {
  name: 'free-lesson-page',

  props: ['block_data', 'custom_fields'],

  components: {
    DefaultForm
  },

  data() {
    return {
      ui: {
        is_mobile_resolution: false,
        is_desktop_resolution: false,
      },

      fax_numbers: [],
      phone_numbers: [],
    }
  },

  created() {
    // console.log('block_data', this.block_data, 'custom_field', this.custom_fields);

    this.setPhoneNumbers();
    this.setFaxNumbers();
  },

  mounted() {
    let self = this;

    window.addEventListener('resize', self._onResize);
    self._onResize();
  },

  beforeDestroy() {
    window.removeEventListener('resize', this._onResize);
  },

  methods: {
    setFaxNumbers() {
      if (!this.custom_fields.contact_fax) {
        return
      }
      this.fax_numbers = this.custom_fields.contact_fax.split(',');
    },

    setPhoneNumbers() {
      if (!this.custom_fields.contact_phone) {
        return
      }

      this.phone_numbers = this.custom_fields.contact_phone.split(',');
    },

    _onResize: function () {
      this._updateResolutionType();
    },

    _updateResolutionType: function () {

      if (window.innerWidth <= 1024 || (window.innerWidth <= 1024 && window.matchMedia("(orientation: landscape)").matches)) {
        this.ui.is_mobile_resolution = true;
        this.ui.is_desktop_resolution = false;
      } else {
        this.ui.is_mobile_resolution = false;
        this.ui.is_desktop_resolution = true;
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.content_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;

  @media (min-width: 1024px) {
    flex-direction: row;
    align-items: unset;
    gap: 100px;
  }

  .contact_wrapper {
    width: 100%;
    max-width: 405px;

    .contact_container {
      display: flex;
      flex-direction: column;
      gap: 26px;

      .contact_item {
        display: flex;
        justify-content: space-between;

        &:not(:last-of-type) {
          padding-bottom: 26px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.10);
        }

        .left {
          color: #333;
          font-family: inherit;
          font-size: 18px;
          font-weight: 500;
        }

        .right {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          color: #333;
          font-family: inherit;
          font-size: 18px;
          font-weight: 400;
        }
      }
    }
  }

}
</style>