<template>
    <div class="content_wrapper" v-if="array_elements.length > 0">
        <table class="base_table">
            <tr class="base_table_header">
                <th colspan="2">{{ table_title }}</th>
            </tr>

            <tr class="base_table_content" v-for="pair in pairs">
                <span class="left" v-html="getLeftText(pair)"></span>
                <span class="right" v-html="getRightText(pair)"></span>
            </tr>
        </table>
    </div>
</template>

<script>

export default {
    name: 'base-table',

    props: {
        table_title: {
            type: String
        },
        array_elements: {
            type: Array
            // default: () => []
        },
    },

    data() {
        return {
            array_left_column_elements: [],
            array_right_column_elements: [],
            pairs: [],
        }
    },

    computed: {
    },

    emits: [],

    mounted() {
        this.getPairs();
    },

    created() {
    },

    methods: {
        getLeftText(el) {
            if (el) {
                let left_text = el.split('/')[0];
                return left_text;

            } else {
                return '';
            }
        },

        getLeftTableElements() {
            this.array_elements.forEach(element => {
                this.array_left_column_elements.push(element[Object.keys(element)[0]])

                return this.array_left_column_elements;
            });
        },

        getRightTableElements() {
            this.array_elements.forEach(element => {
                this.array_right_column_elements.push(element[Object.keys(element)[1]])

                return this.array_right_column_elements;
            });
        },

        getPairs() {
            this.getLeftTableElements();
            this.getRightTableElements();

            for (let i = 0; i < this.array_right_column_elements.length; i++) {
                let x = this.array_left_column_elements[i];
                let y = this.array_right_column_elements[i];
                let pair = x + '/' + y;
                    if (!this.pairs.includes(pair)) {
                        this.pairs.push(pair)
                    }
            }
        },

        getRightText(el) {
            if (el) {
                let right_text = el.split('/')[1];
                return right_text;

            } else {
                return '';
            }
        }
    },
}
</script>

<style lang="scss" scoped>
.base_table {
    border-collapse: collapse;
    width: 100%;
    // min-width: 325px;
    // max-width: 500px;
    display: flex;
    flex-direction: column;

    // @media (min-width: 1024px) {
    //     padding: 0 10px;
    // }

    .base_table_header {
        background-color: #829bb2;
        text-align: left;
        color: white;
        height: 34px;
        padding: 5px;
        display: block;

        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
    }

    tr.base_table_content {
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        padding: 5px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 0;

        // .left,
        // .right {
        //     display: flex;
        //     flex-direction: column;
        // }

        .left {
            width: 20%;
        }

        .right {
            width: 80%;
        }

        &:nth-child(even) {
            background-color: #ebf3fb;
        }

        &:nth-child(odd) {
            background-color: #c2dbf2;
        }

        td.year {
            width: 20%;
        }

        td.title {
            width: 80%;
        }
    }
}
</style>