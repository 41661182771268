<template>
  <div class="content_wrapper">
    <base-title :text="block_data.title"></base-title>

    <div v-if="ui.is_loading_posts">
      <loading-indicator type="spinner"></loading-indicator>
    </div>
    <div v-if="ui.no_posts" class="error_wrapper">
      <p>Error</p>
    </div>
    <div v-if="!ui.is_loading_posts && !ui.no_posts" class="posts_wrapper" :class="{ 'news': posts.template === 'stiri' }">
      <template v-if="posts.template === 'stiri'">
        <div class="post_wrapper--news" :style="width_style" v-for="(post, index) in current_posts" :key="index">
          <news-card :post_content="post.content" :post_slug="post.slug" :storage_path="storage_path" :cache_custom_field="custom_field" :fallback-logo="true"></news-card>
        </div>
      </template>
      <template v-else-if="posts.template === 'antrenor'">
        <div class="post_wrapper--news" :style="width_style" v-for="(post, index) in current_posts" :key="index">
          <trainer-card :post_content="post.content" :post_slug="post.slug" :storage_path="storage_path" :cache_custom_field="custom_field" :fallback-logo="true"></trainer-card>
        </div>
      </template>
      <template v-else-if="posts.template === 'sportiv'">
        <div class="post_wrapper--news" :style="width_style" v-for="(post, index) in current_posts" :key="index">
          <athlete-card :post_content="post.content" :post_slug="post.slug" :storage_path="storage_path" :cache_custom_field="custom_field" :fallback-logo="true"></athlete-card>
        </div>
      </template>
    </div>

    <div v-if="!ui.is_loading_posts && !ui.no_posts && has_pagination && total_pages > 1" class="pagination_wrapper">
      <button class="btn-pagination" :disabled="current_page === 1" @click="goToFirstPage">
        <double-angle-left-svg :color="ui.pagination_svg_color" />
      </button>
      <button class="btn-pagination" :disabled="current_page === 1" @click="goToPreviousPage">
        <angle-left-svg :color="ui.pagination_svg_color" />
      </button>

      <button v-for="page in display_pages" :key="page" :class="{ active_page: current_page === page, 'btn-pagination': typeof page !== 'string' }" @click="goToPage(page)">
        {{ page }}
      </button>

      <button class="btn-pagination" :disabled="current_page === total_pages" @click="goToNextPage">
        <angle-right-svg :color="ui.pagination_svg_color" />
      </button>
      <button class="btn-pagination" :disabled="current_page === total_pages" @click="goToLastPage">
        <double-angle-right-svg :color="ui.pagination_svg_color" />
      </button>
    </div>
  </div>
</template>

<script>

import axios from 'axios';

import BaseTitle from './common/BaseTitle.vue';
import LoadingIndicator from './common/LoadingIndicator.vue';

import NewsCard from './cards/NewsCard.vue';
import TrainerCard from './cards/TrainerCard.vue';
import AthleteCard from './cards/AthleteCard.vue';

import AngleRightSvg from './svg-components/AngleRightSvg.vue';
import AngleLeftSvg from './svg-components/AngleLeftSvg.vue';
import DoubleAngleRightSvg from './svg-components/DoubleAngleRightSvg.vue';
import DoubleAngleLeftSvg from './svg-components/DoubleAngleLeftSvg.vue';

export default {
  name: 'posts-grid',

  props: {
    storage_path: String,
    block_data: Object,
    custom_field: Object
  },

  components: {
    BaseTitle,
    LoadingIndicator,

    NewsCard,
    TrainerCard,
    AthleteCard,

    AngleRightSvg,
    AngleLeftSvg,
    DoubleAngleRightSvg,
    DoubleAngleLeftSvg,
  },

  data() {
    return {
      ui: {
        is_loading_posts: false,
        no_posts: false,
        is_mobile_resolution: false,
        is_desktop_resolution: false,
      },

      columns_mobile: 1,
      columns_desktop: 3,

      has_pagination: false,

      current_page: 1,
      total_pages: 0,
      posts_per_page: 6,
      columns_mobile: 1,
      columns_desktop: 3,

      posts_base_api: '/api/getPostByCategoryId/',
      posts: {},
    }
  },

  computed: {
    current_posts() {
      let self = this;
      if (self.posts.template == 'sportiv') {
        self.posts.values.sort((a, b) => a.content.name.localeCompare(b.content.name, 'ro', { sensitivity: 'base' }));
      }
      if (self.has_pagination) {

        let start = (self.current_page - 1) * self.posts_per_page;
        let end = start + self.posts_per_page;
        return self.posts.values.slice(start, end);

      } else {

        return self.posts.values;
      }

      // if (self.posts.template == 'stiri') {
      //   self.posts.values.sort(function (a, b) {
      //     var aa = self.getDate(a.content.date).split('.').reverse().join(),
      //       bb = self.getDate(b.content.date).split('.').reverse().join();

      //     return aa < bb ? -1 : (aa > bb ? 1 : 0);
      //   }).reverse();
      // }

      // return self.posts.values;
    },

    display_pages() {

      let total_pages = this.total_pages;
      let page = this.current_page;
      let pages = [];

      if (total_pages <= 6) {
        pages = this.total_pages;
      } else {

        if (page < total_pages - 2) {
          if (page == 1) {
            page = page + 1;
          }
          pages = [page - 1, page, page + 1, '...', total_pages - 1, total_pages];

        } else {
          pages = [1, 2, '...', total_pages - 2, total_pages - 1, total_pages];
        }
      }

      return pages;
    },

    width_style() {

      if (this.current_posts.length > 0) {
        return {
          'width': `${100 / (this.ui.is_mobile_resolution ? this.columns_mobile : this.columns_desktop)}%`
        };
      } else {
        return 'width: unset';
      }
    },
  },

  watch: {},

  created() {

    let self = this;

    self.setHasPagination();
    self.setColumns();

    self.getPosts();

    console.log('block_data', this.block_data);

    window.addEventListener('resize', self._onResize);
    self._onResize();
  },

  mounted() { },

  beforeDestroy() {
    window.removeEventListener('resize', this._onResize);
  },

  methods: {
    getPosts() {
      var self = this;

      const post_category = self.block_data.post_categories;
      const posts_base_api = self.posts_base_api;

      const posts_api = posts_base_api + post_category;

      self.ui.is_loading_posts = true;

      axios.get(posts_api)
        .then((response) => {
          if (response.data.values.length) {

            let data = response.data;

            for (let key in data) {

              Vue.set(self.posts, key, data[key])
            }

            if (self.has_pagination) {
              self.setPostsPerPage();
              self.setTotalPages();
            }
            // console.log("self.has_pagination",self.has_pagination);
            // console.log('getPosts', self.posts);

          } else {

            self.ui.no_posts = true;

          }

          self.ui.is_loading_posts = false;

        }).catch((error) => {

          self.ui.no_posts = true;
          self.ui.is_loading_posts = false;
        })
    },


    setColumns() {
      let self = this;

      let columns_mobile_from_block_data = typeof self.block_data.nb_of_columns_mobile === 'string' ? parseInt(self.block_data.nb_of_columns_mobile) : self.block_data.nb_of_columns_mobile,
        columns_desktop_from_block_data = typeof self.block_data.nb_of_columns_desktop === 'string' ? parseInt(self.block_data.nb_of_columns_desktop) : self.block_data.nb_of_columns_desktop;

      self.columns_desktop = columns_desktop_from_block_data;
      self.columns_mobile = columns_mobile_from_block_data;
    },

    setPostsPerPage() {
      let self = this;

      let posts_per_page_from_block_data = typeof self.block_data.nb_of_posts === 'string' ? parseInt(self.block_data.nb_of_posts) : self.block_data.nb_of_posts;

      self.posts_per_page = posts_per_page_from_block_data;
    },

    setTotalPages() {
      this.total_pages = Math.ceil(this.posts.values.length / this.posts_per_page);

      // console.log('total_pages', this.total_pages);
    },

    setHasPagination() {
      this.has_pagination = this.block_data.has_pagination === 'true' ? true : false;
      console.log("this.has_pagination",this.has_pagination);

    },

    goToFirstPage() {
      this.current_page = 1;
    },

    goToPreviousPage() {
      this.current_page--;
    },

    goToPage(page_number) {

      if (typeof page_number === 'string') {
        return
      }
      this.current_page = page_number;
    },

    goToNextPage() {
      this.current_page++;
    },

    goToLastPage() {
      this.current_page = this.total_pages;
    },


    getDate(date_details) {

      if (date_details) {
        let date = date_details.split('/')[0];
        return date;

      } else {
        return '';
      }
    },

    _onResize: function () {
      this._updateResolutionType();
    },

    _updateResolutionType: function () {

      if (window.innerWidth <= 1024 || (window.innerWidth <= 1024 && window.matchMedia("(orientation: landscape)").matches)) {
        this.ui.is_mobile_resolution = true;
        this.ui.is_desktop_resolution = false;
      } else {
        this.ui.is_mobile_resolution = false;
        this.ui.is_desktop_resolution = true;
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.content_wrapper {
  .error_wrapper {
    display: flex;
    justify-content: center;
  }

  .posts_wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    // justify-content: center;
    // margin: -10px;

    @media (min-width: 1024px) {
      // margin: -15px;
    }

    &.news {
      // margin: -25px -10px;

      // @media (min-width: 1024px) {
      //   margin: -25px;
      // }
    }

    &.careers {
      // margin: -20px -15px;
      align-items: unset;
    }

    .post_wrapper--news {
      padding: 0 10px;
      align-self: stretch;
      height: 100%;

      // @media (min-width: 1024px) {
      //   padding: 0 25px;
      // }

      padding-bottom: 10px;

      @media (min-width: 1024px) {
        padding-bottom: 25px;
      }
    }

    .post_wrapper--career {
      padding: 20px 15px;

      @media (min-width: 1024px) {
        padding: 15px;
      }
    }

    .post_wrapper--testimonial {
      padding: 10px;

      @media (min-width: 1024px) {
        padding: 15px;
      }
    }
  }

  .pagination_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;

    @media (min-width: 1024px) {
      gap: 10px;
    }

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      margin: 0;
      padding: 0;
      border: none;
      background-color: transparent;

      font-size: 18px;
      color: #69AFAF;
    }

    .btn-pagination {
      cursor: pointer;

      &.active_page {
        border: 1px solid #69AFAF;
      }

      &:disabled {
        opacity: 0.5;
        cursor: default;
      }
    }
  }
}
</style>