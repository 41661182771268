<template>
  <div class="base-image_wrapper" :class="{ 'fullscreen': is_fullscreen }">
    <img v-if="is_loading" :src="error_src" :alt="imgAlt" />
    <!-- <div v-if="is_loading">
      <loading-indicator type="spinner"></loading-indicator>
    </div> -->
    <img v-show="!is_loading" :class="{ 'fullscreen': is_fullscreen }" :src="imgSrc" :alt="imgAlt" @load="imageLoaded" @error="imageFailed" />
  </div>

</template>

<script>
import LoadingIndicator from './LoadingIndicator.vue';
export default {
  name: 'base-image',

  props: {
    imgSrc: {
      type: String,
      default: ''
    },
    is_fullscreen: {
      type: Boolean,
      default: false
    }
  },

  emits: ["imgError"],

  components: {
    LoadingIndicator,
  },

  data() {
    return {
      is_loading: true,

      has_error: false,
      error_src: 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7',
    }
  },

  computed: {
    imgAlt() {
      let self = this;

      let index = self.imgSrc.lastIndexOf("/") + 1;
      let filename = self.imgSrc.substr(index);
      let img_alt = filename.split('.')[0];

      return img_alt;
    }
  },

  methods: {
    imageLoaded(ev) {

      this.is_loading = false;
    },

    imageFailed(ev) {

      this.is_loading = false;
      this.has_error = true;

      ev.target.src = this.error_src;
      if (this.$listeners.imgError) {
        this.$emit('imgError', true);
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.base-image_wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;

  &.fullscreen {
    width: auto;
  }

  .image_loading,
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  img {
    &.fullscreen {
      object-fit: contain;
    }
  }
}
</style>