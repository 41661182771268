<template>
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_538_201)">
            <path d="M13.5 17.5L18.5 12.5L13.5 7.5" stroke="#676767" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M7 16.5L11 12.5L7 8.5" stroke="#676767" stroke-opacity="0.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M-1.5 17.5L3.5 12.5L-1.5 7.5" stroke="#676767" stroke-opacity="0.25" stroke-linecap="round" stroke-linejoin="round" />
        </g>
        <defs>
            <clipPath id="clip0_538_201">
                <rect width="24" height="24" fill="white" transform="translate(0.5 0.5)" />
            </clipPath>
        </defs>
    </svg>

</template>

<script>

export default {
    name: 'chevrons-right-svg',
    props: {
        color: {
            type: String,
            default: '#000000'
        }
    }
}
</script>

<style lang="scss" scoped></style>