<template>
    <div class="hp_slider">

        <div v-if="is_loading_posts">
            <loading-indicator type="spinner"></loading-indicator>
        </div>

        <button class="hp_slider_prev_btn">
            <img :src="image_file_path + '/angle-left.svg'" alt="">
        </button>
        <button class="hp_slider_next_btn">
            <img :src="image_file_path + '/angle-right.svg'" alt="">
        </button>

        <div v-if="!is_loading_posts" :id="carousel_id">
            <div class="hp_slider_container" v-for="(slide, slide_index) in slides" :key="slide_index">
                <a :href="slide.slug">
                    <div class="hp_slider_overlay"></div>
                    <div class="hp_slider_image">
                        <img :src="storage_file_path + '/' + slide.content.carousel[0]" :alt="image_alt(slide.content.carousel[0])">
                    </div>
    
                    <div class="hp_slider_text">
                        <p class="hp_slider_subtitle" v-html="getDate(slide.content.date)"></p>
                        <h2 class="hp_slider_title" v-html="slide.content.title"></h2>
                    </div>
                </a>
            </div>
        </div>

    </div>
</template>

<script>
import axios from "axios";
import LoadingIndicator from './common/LoadingIndicator.vue';

export default {
    name: 'hp-slider',

    props: ['image_path', 'storage_path', 'slider'],

    components: {
        LoadingIndicator,
    },

    computed: {
        image_file_path() {
            const self = this;
            return self.image_path;
        },

        storage_file_path() {
            const self = this;
            return self.storage_path;
        },

        slider_data() {
            const self = this;
            return self.slider;
        },

        slides() {
            const self = this;

            if (self.posts.values) {
                let slides_content = [];

                let slicedArray = self.posts.values.slice(0, 5);

                slicedArray.forEach(element => {
                    slides_content.push(element)
                    // slides_content.slugs.push(element.slug)
                });

                // slides_content.sort(function (a, b) {
                //     a = self.getDate(a.content.date);
                //     b = self.getDate(b.content.date);

                //     return b.localeCompare(a);
                // });

                return slides_content;
            }
        }
    },

    data() {
        return {
            current_index: 0,
            carousel_id: '',
            prev_slide: null,
            current_slide: null,
            next_slide: null,

            is_loading_posts: false,
            no_posts: false,

            ui: {
                is_mobile_resolution: false,
                is_desktop_resolution: false,
            },

            posts_base_api: '/api/getPostByCategoryId/',
            posts: {},
        }
    },

    watch: {
        current_index(new_val, old_val) {
            let self = this;

            if (self.ui.is_desktop_resolution) {
                self.updateSlickCarousel();

                let prev_slide_text_el = self.prev_slide[0].querySelector('.hp_slider_text');
                let next_slide_text_el = self.next_slide[0].querySelector('.hp_slider_text');

                if (old_val > new_val) {
                    next_slide_text_el.style.left = '0';
                    next_slide_text_el.style.right = 'unset';
                } else {
                    next_slide_text_el.style.left = 'unset';
                    prev_slide_text_el.style.left = 'unset';
                }
            }
        }
    },

    mounted() {
        let self = this;

        self.getPosts();

        window.addEventListener('resize', self._onResize);
        self._onResize();
    },

    beforeDestroy() {
        window.removeEventListener('resize', this._onResize);
    },


    updated() {
        this.initSlickCarousel();
    },

    methods: {
        initSlickCarousel() {

            let self = this;

            if (self.carousel_id) {
                const id_selector = "#" + self.carousel_id;
                $(id_selector).slick({
                    dots: false,
                    arrows: true,
                    infinite: true,
                    autoplay: false,
                    speed: 300,
                    slidesToShow: 1,
                    centerMode: true,
                    variableWidth: true,
                    prevArrow: $('.hp_slider_prev_btn'),
                    nextArrow: $('.hp_slider_next_btn'),
                    responsive: [
                        {
                            breakpoint: 1024,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1,
                                dots: false,
                                arrows: false,
                                variableWidth: false,
                            }
                        },
                    ],
                });

                $(id_selector).on('afterChange', function (event, slick, currentSlide, nextSlide) {
                    self.current_index = currentSlide;
                });


                self.updateSlickCarousel();
            }


        },

        updateSlickCarousel() {

            let self = this;

            const id_selector = '#' + self.carousel_id;

            if (self.ui.is_desktop_resolution) {
                self.next_slide = $(id_selector).find('[data-slick-index="' + (self.current_index + 1) + '"]');
                self.current_slide = $(id_selector).find('[data-slick-index="' + (self.current_index) + '"]');
                self.prev_slide = $(id_selector).find('[data-slick-index="' + (self.current_index - 1) + '"]');

                let prev_next_actual_width = (window.innerWidth - self.next_slide[0].clientWidth) / 2;

                let next_slide_text_el = self.next_slide[0].querySelector('.hp_slider_text');
                let current_slide_text_el = self.current_slide[0].querySelector('.hp_slider_text');
                let prev_slide_text_el = self.prev_slide[0].querySelector('.hp_slider_text');

                let prev_btn = document.querySelector('.hp_slider_prev_btn');
                prev_btn.style.left = `${prev_next_actual_width + 20}px`

                $(".hp_slider_prev_btn").click(function () {
                    $(id_selector).slick("slickPrev");
                });

                let next_btn = document.querySelector('.hp_slider_next_btn');
                next_btn.style.right = `${prev_next_actual_width + 20}px`

                $(".hp_slider_next_btn").click(function () {
                    $(id_selector).slick("slickNext");
                });

                prev_slide_text_el.style.width = `${prev_next_actual_width}px`;
                prev_slide_text_el.style.right = '0';

                current_slide_text_el.style.width = `100%`;

                next_slide_text_el.style.width = `${prev_next_actual_width}px`;
            }
        },

        getPosts() {
            var self = this;

            const post_category = self.slider_data.post_categories;
            const posts_base_api = self.posts_base_api;

            const posts_api = posts_base_api + post_category;

            self.is_loading_posts = true;

            axios.get(posts_api)
                .then((response) => {
                    if (response.data.values.length) {

                        let data = response.data;

                        for (let key in data) {

                            Vue.set(self.posts, key, data[key])
                        }

                        // console.log('getPosts', self.posts);

                        self.setCarouselId();

                    } else {

                        self.no_posts = true;
                    }

                    self.is_loading_posts = false;

                }).catch((error) => {

                    self.no_posts = true;
                    self.is_loading_posts = false;
                })
        },

        setCarouselId() {

            this.carousel_id = 'posts-' + this.slider_data.post_categories;
        },

        getDate(date_details) {

            if (date_details) {
                let date = date_details.split('/')[0];
                return date;

            } else {
                return '';
            }
        },

        
        image_alt (slide_image) {
            
            if (slide_image) {
                let img_alt = slide_image.split('.')[0];

                return img_alt;
            }
        },

        _onResize: function () {
            this._updateResolutionType();
        },

        _updateResolutionType: function () {

            if (window.innerWidth <= 1024 || (window.innerWidth <= 1024 && window.matchMedia("(orientation: landscape)").matches)) {
                this.ui.is_mobile_resolution = true;
                this.ui.is_desktop_resolution = false;
            } else {
                this.ui.is_mobile_resolution = false;
                this.ui.is_desktop_resolution = true;
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.hp_slider {
    height: 200px;
    // width: 1024px;
    position: relative;
    margin: 0 auto;

    @media (min-width: 1024px) {
        height: 530px;
    }

    #posts-1 {
        height: 100%;

        .hp_slider_container {
            height: 200px;
            position: relative;

            @media (min-width: 1024px) {
                height: 530px;
            }

            .hp_slider_overlay {
                background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border-radius: 10px;
                z-index: 1;
            }

            .hp_slider_image {
                width: 100%;
                height: 100%;
                position: relative;

                @media (min-width: 1024px) {
                    // width: 984px;
                    border-radius: 10px;
                    overflow: hidden;
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    display: block;
                }
            }

            .hp_slider_text {
                position: absolute;
                bottom: 0;
                padding: 20px;
                color: #fff;
                z-index: 2;

                .hp_slider_title {
                    font-size: 18px;
                    font-weight: 700;
                    line-height: 24px;
                }

                .hp_slider_subtitle {
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                }
            }
        }
    }

    .hp_slider_prev_btn {
        display: none;

        @media (min-width: 1024px) {
            position: absolute;
            left: 20px;
            background: none;
            border: none;
            z-index: 2;
            cursor: pointer;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .hp_slider_next_btn {
        display: none;

        @media (min-width: 1024px) {
            position: absolute;
            right: 20px;
            background: none;
            border: none;
            z-index: 2;
            cursor: pointer;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}
</style>