<template>
  <div class="form_wrapper" :class="type">
    <base-title v-if="block_data.title" :text="block_data.title" :text-align-center="is_mobile_resolution"></base-title>

    <div v-if="block_data.description" class="text_wrapper" v-html="block_data.description"></div>

    <form id="contact-form" autocomplete="on">
      <div class="wrapper">

        <div class="wrapper-left">
          <div class="control-group" :class="{ invalid: form.parent_full_name_validity === 'invalid' }">
            <label for="contact-form-full-name">
              {{ getLabelText('form_parent_full_name') }}
            </label>
            <input type="text" name="contact-form-full-name" id="contact-form-full-name" v-model.trim="form.parent_full_name" @blur="validateParentFullName">
          </div>
          
          <div class="control-group" :class="{ invalid: form.child_name_validity === 'invalid' }">
            <label for="contact-form-child-name">
              {{ getLabelText('form_child_name') }}
            </label>
            <input type="text" name="contact-form-child-name" id="contact-form-child-name" v-model.trim="form.child_name" @blur="validateChildName">
          </div>


          <div class="control-group" :class="{ invalid: form.child_age_validity === 'invalid' }">
            <label for="contact-form-child-age">
              {{ getLabelText('form_child_age') }}
            </label>
            <input type="text" name="contact-form-child-age" id="contact-form-child-age" v-model.trim="form.child_age" @blur="validateChildAge">
          </div>

          <div class="control-group" :class="{ invalid: form.phone_number_validity === 'invalid' }">
            <label for="contact-form-phone">
              {{ getLabelText('form_phone') }}
            </label>
            <input type="number" name="contact-form-phone" id="contact-form-phone" v-model.trim="form.phone_number" @blur="validatePhoneNumber">
          </div>
        </div>
      </div>

      <div class="control-privacy">
        <input type="checkbox" name="contact-form-privacy" id="contact-form-privacy" class="privacy_checkbox" v-model="form.privacy" @change="validatePrivacy" />
        <label for="contact-form-privacy" v-html="getPrivacyText()"></label>
      </div>

      <div class="control-btn">
        <button class="button_submit" @click.prevent="sendEmail">
          <span>
            {{ block_data.text_button }}
          </span>
          <ChevronsRight :color="svg_color" />
        </button>
      </div>
    
      <!-- <Transition name="fade" >
        <div v-if="ui.notification_visible" class="notification success">
          <p>{{ custom_fields.form_notification_success }}</p>
        </div>
      </Transition> -->
    </form>
  </div>
</template>

<script>

import axios from 'axios';

import ChevronsRight from '../svg-components/ChevronsRight.vue';

export default {
  name: 'default-form',

  props: ['block_data', 'custom_fields', 'is_mobile_resolution', 'type'],

  components: { ChevronsRight },

  data() {
    return {
      ui: {
        svg_color_desktop: '#2C4E9B',
        svg_color_mobile: '#ffffff',
        notification_visible: false,
      },

      api_email: '/api/sendEmail',

      form_labels: {},

      form: {
        parent_full_name: '',
        child_name: '',
        child_age: '',
        phone_number: '',
        privacy: false,

        parent_full_name_validity: 'pending',
        child_name_validity: 'pending',
        child_age_validity: 'pending',
        phone_number_validity: 'pending',

        privacy_validity: 'pending',
      },
    }
  },

  computed: {
    svg_color() {
      if (this.is_mobile_resolution) {
        return this.ui.svg_color_mobile;
      } else {
        return this.ui.svg_color_desktop
      }
    },

    formValidity: function () {
      let form_validity = true;

      if (!this.validateParentFullName()) {
        form_validity = false;
      }
      if (!this.validateChildName()) {
        form_validity = false;
      }
      if (!this.validateChildAge()) {
        form_validity = false;
      }
      if (!this.validatePhoneNumber()) {
        form_validity = false;
      }
      if (!this.validatePrivacy()) {
        form_validity = false;
      }

      return form_validity;
    },
  },

  created() {
    this.setFormLabels();
    // console.log('block_data', this.block_data, 'custom_field', this.custom_fields);
    console.log('form_labels', this.form_labels);
  },

  methods: {
    setFormLabels() {
      this.block_data.info.labels.forEach(label => {
        this.form_labels[label.name] = { 'required': label.required }
      })
    },

    getPrivacyText() {
      let text = '';

      text = this.custom_fields.privacy_policy;

      return text;
    },

    getLabelText(label_key) {
      let label_text = this.custom_fields[label_key];

      if (this.form_labels[label_key].required) {
        label_text += '*';
      }

      return label_text;
    },

    validateParentFullName() {
      if (!this.form_labels.form_parent_full_name.required) {
        return true;
      }

      if (this.form.parent_full_name === '') {
        this.form.parent_full_name_validity = 'invalid';
        return false;
      } else {
        this.form.parent_full_name_validity = 'valid';
        return true;
      }
    },

    validateChildName() {
      if (!this.form_labels.form_child_name.required) {
        return true;
      }

      if (this.form.child_name === '') {
        this.form.child_name_validity = 'invalid';
        return false;
      } else {
        this.form.child_name_validity = 'valid';
        return true;
      }
    },

    validateChildAge() {
      console.log(this.form);
      if (!this.form_labels.form_child_age.required) {
        return true;
      }

      if (this.form.child_age === '') {
        this.form.child_age_validity = 'invalid';
        return false;
      } else {
        this.form.child_age_validity = 'valid';
        return true;
      }
    },

    validatePhoneNumber() {
      if (!this.form_labels.form_phone.required) {
        return true;
      }

      if (this.form.phone_number === '') {
        this.form.phone_number_validity = 'invalid';
        return false;
      } else {
        this.form.phone_number_validity = 'valid';
        return true;
      }
    },

    validatePrivacy() {
      if (!this.form.privacy) {
        this.form.privacy_validity = 'invalid';
        return false;
      } else {
        this.form.privacy_validity = 'valid';
        return true;
      }
    },

    resetForm: function () {
      this.form.parent_full_name = '';
      this.form.child_name = '';
      this.form.child_age = '';
      this.form.phone_number = '';
      this.form.privacy = false;
    },

    showNotification() {
      this.ui.notification_visible = true;

      // setTimeout(() => {
      //   this.ui.notification_visible = false;
      // }, 5000)
    },

    sendEmail: function () {
      if (!this.formValidity) {
        return;
      }

      const form_type = this.block_data.info.type;

      const form_data = {
        type: form_type,
        parent_full_name: this.form.parent_full_name,
        child_name: this.form.child_name,
        child_age: this.form.child_age,
        phone_number: this.form.phone_number,
      }

      const email_api_url = this.api_email;

      axios({
        method: 'post',
        url: email_api_url,
        data: form_data,
        headers: { "Content-Type": "application/json" }

      }).then((response) => {
        console.log('Send default form to email response - ', response);

        if (response.data.success === 'success') {
          console.log('success');

          // this.showNotification();
          this.resetForm();

        } else {
          console.log('api error');
        }

      }).catch(error => {
        console.log('Could not perform send email post - ', error);
        console.log('Api error.response.data - ', error.response.data)

        const errors = error.response.data.errors;

        if (errors) {
          if (errors.parent_name) {
            this.form.parent_full_name_validity = 'invalid';
          }
          if (errors.child_name) {
            this.form.child_name_validity = 'invalid';
          }
          if (errors.child_age) {
            this.form.child_age_validity = 'invalid';
          }
          if (errors.phone) {
            this.form.phone_number_validity = 'invalid';
          }
        }
      });
    },
  },
}
</script>

<style lang="scss" scoped>
.form_wrapper {
  width: 100%;
  max-width: 610px;

  #contact-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;

    .wrapper,
    .wrapper-left,
    .wrapper-right {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .control-group {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 10px;
      padding: 10px 0;
      position: relative;
      isolation: isolate;

      @media (min-width: 1024px) {
        padding: 10px;
      }

      &:nth-child(even) {
        &::before {
          content: "";
          background: rgba(174, 207, 238, 0.1);
          display: block;
          height: 100%;
          position: absolute;
          top: 0;
          left: -10px;
          width: 100vw;
          z-index: -1;

          @media (min-width: 1024px) {
            width: 100%;
            left: 0;
          }
        }
      }

      &:nth-child(odd) {
        &::before {
          content: "";
          background: rgba(174, 207, 238, 0.34);
          display: block;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          left: -10px;
          width: 100vw;
          z-index: -1;

          @media (min-width: 1024px) {
            width: 100%;
            left: 0;
          }
        }

      }

      &.invalid {

        input {
          border: 1px solid var(--red);
        }

      }

      label {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: #000;

        // @media (min-width: 1024px) {
        //   font-size: 18px;
        //   line-height: 21px;
        // }

        ::v-deep a {
          color: #000;
          text-decoration: underline;
        }

        a {
          color: #000;
          text-decoration: underline;
        }
      }

      input {
        width: 100%;
        padding: 7px;

        border-radius: 10px;
        border: 1px solid #333;
        background: #FFF;

        font-size: 16px;
        font-family: inherit;
        transition: all 0.3s ease-in-out;

        &:focus,
        &:focus-visible {
          outline: none;
        }

        @media (min-width: 1024px) {
          font-size: 18px;
          padding: 5px;
        }
      }
    }

    .control-privacy {
      display: flex;
      flex-direction: row;
      width: 100%;
      gap: 10px;
      padding: 10px 0 30px 0;
      position: relative;

      .privacy_checkbox {
        appearance: none;
        display: grid;
        place-content: center;
        width: 26px;
        height: 26px;
        margin: 0 auto;
        border: 1px solid #333;
        border-radius: 10px;
        background-color: #fff;
        color: #333;
        cursor: pointer;

        &::before {
          content: "";
          width: 15px;
          height: 13px;
          -webkit-clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
          clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
          transform: scale(0);
          transform-origin: center;
          transition: 120ms transform ease-in-out;
          box-shadow: inset 1em 1em var(--text_color);
        }

        &:checked::before {
          transform: scale(1);
        }
      }

      input {
        width: 100%;
        padding: 10px;

        border-radius: 10px;
        border: 1px solid #333;
        background: #FFF;

        font-size: 16px;
        font-family: inherit;
        transition: all 0.3s ease-in-out;

        &:focus,
        &:focus-visible {
          outline: none;
        }

        @media (min-width: 1024px) {
          font-size: 18px;
        }
      }

      label {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: #000;

        // @media (min-width: 1024px) {
        //   font-size: 18px;
        //   line-height: 21px;
        // }

        ::v-deep a {
          color: #000;
          text-decoration: underline;
        }

        a {
          color: #000;
          text-decoration: underline;
        }
      }
    }

    .button_submit {
      background-color: unset;
      border: 1px solid rgba(103, 103, 103, 0.5);
      height: 34px;
      padding: 5px 15px;
      border-radius: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      cursor: pointer;

      span {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: #676767;
        margin-right: 10px;
        font-family: var(--default-font);
      }

      img {
        width: 24px;
        height: 24px;
      }
    }
  }

  .notification {
    width: 100%;
    margin-bottom: 20px;
    padding: 10px;
    text-align: center;

    p {
      color: #FFF;
      text-align: center;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.72px;
    }

    &.success {
      background-color: #62AF1D;
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: all 0.3s ease-in-out;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

}
</style>