<template>
    <div class="trainer">
        <base-title v-if="slider_data.name" :text="slider_data.name"></base-title>

        <div class="trainer_content">
            <div class="trainer_details" :class="{ no_image: !slider_data.image }">
                <div class="trainer_description">
                    <div class="trainer_text" v-if="slider_data.text" v-html="slider_data.text">
                    </div>
                </div>

            </div>

            <div class="trainer_image" v-if="slider_data.image">
                <base-image :imgSrc="getImageSrc(slider_data)"></base-image>
            </div>

        </div>

        <base-table v-if="slider_data.titles_label && slider_data.titles" :table_title="slider_data.titles_label" :array_elements="slider_data.titles"></base-table>
    </div>
</template>

<script>
import BaseTitle from "./common/BaseTitle.vue"
import BaseImage from "./common/BaseImage.vue"
import BaseTable from "./common/BaseTable.vue"

export default {
    name: 'section-trainer',

    props: ['storage_path', 'block_data', 'custom_fields'],

    components: {
        BaseTitle,
        BaseImage,
        BaseTable
    },

    data() {
        return {
            svg_color: '#2C4E9B',
        }
    },

    computed: {
        storage_file_path() {
            const self = this;
            return self.storage_path;
        },

        slider_data() {
            const self = this;

            return self.block_data
        },

        has_images() {
            let has_images = false;

            if (this.block_data.images.length) {
                for (let image of this.block_data.images) {
                    if (image.image) {
                        has_images = true;
                        break
                    }
                }
            }

            return has_images;
        }
    },

    created() {
        console.log('block_data', this.block_data)
    },

    methods: {
        getLink() {
            let document_link = this.getDocumentSrc(this.slider_data.file_pdf);

            let site_link;

            return site_link = document_link;
        },

        getDocumentSrc(document_url) {

            if (document_url) {

                if (document_url.indexOf('http') !== -1) {
                    return document_url;
                } else {
                    return this.storage_path + '/' + document_url;
                }

            } else {
                return '';
            }
        },

        getImageSrc(slide) {

            let image_path = "";

            if (slide.image) {
                image_path = slide.image;
            } else if (slide.content && slide.content.image) {
                image_path = slide.content.image;
            } else if (slide.content && slide.content.images && slide.content.images[0] && slide.content.images[0].image) {
                image_path = slide.content.images[0].image;
            } else if (slide.file_name) {
                image_path = slide.file_name;
            }

            if (!image_path.includes('http')) {
                image_path = this.storage_file_path + '/' + image_path;
            }

            return image_path;
        },
    }
}
</script>

<style lang="scss" scoped>
.trainer {
    width: 100%;
    position: relative;

    .trainer_details {
        order: 2;

        @media (min-width: 1024px) {
            width: 50%;
            order: 1;
        }

        .trainer_description {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            color: #000;

            @media (min-width: 1024px) {
                font-size: 18px;
            }
        }

        &.no_image {
            @media (min-width: 1024px) {
                width: 100%;
            }
        }
    }

    .trainer_image {
        display: grid;
        place-content: center;
        order: 1;

        @media (min-width: 1024px) {
            width: 50%;
            order: 2;
        }

        .base-image_wrapper {

            width: 100%;
            aspect-ratio: 1/1;

            @media (min-width: 1024px) {
                max-width: 355px;
            }
        }
    }

    .trainer_content {
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 30px;

        @media (min-width: 1024px) {
            // align-items: center;
            flex-direction: row;
            justify-content: flex-start;
            gap: 0;
        }

        .trainer_text {
            display: flex;
            flex-direction: column;
            text-align: justify;
            width: 100%;
        }

        .trainer_images {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            .trainer_image {
                margin-bottom: 15px;
            }

            a {
                position: relative;
                max-width: 474px;
                width: 100%;
                padding: 10px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-radius: 10px;
                background: var(--text_color);
                color: #FFF;
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                letter-spacing: 0.72px;
            }

            .text_button {
                align-items: center;
                display: flex;
                flex-grow: 1;
                gap: 20px;
                justify-content: space-between;
                padding: 10px;
                background-color: #fff;
                color: #2C4E9B;
                box-shadow: 0 0 3px 0 rgba(0, 0, 0, .25);
            }
        }
    }

    ::v-deep .base_table {
        margin-top: 20px;
    }
}
</style>